import React from 'react';
import BasicButton from '../Buttons/Button.tsx';

const ProbePlug = () => {
  return (
    <div className="mt-28 mb-20">
      {/* <p className="font-thin">Still going to build?</p> */}
      <h1 className="text-2xl font-medium mt-2">
        <span className="bg-blue-800 text-white inline-block px-1 pt-0 pb-1 rounded-md bg-opacity-60">
          Next step?
        </span>{' '}
        Avoid building a product that people don't want.
      </h1>
      <div className="mt-8 text-lg sm:text-xl">
        <p className="font-medium">
          Hey, it's Savannah, founder of BuiltBefore 👋
        </p>
        <p className="mt-7">
          If you liked BuiltBefore, take a look at{' '}
          <a href="heyprobe.com" className="text-indigo-400">
            Probe
          </a>
          , a tool I built to help founders quickly validate their ideas. Probe
          conducts live interviews with your target audience, giving insights in
          just 3 hours.
        </p>
        <ul className="list-disc list-inside mt-6">
          {' '}
          <li>Learn customer's #1 pain points 💊</li>
          <li>How they currently solve their problems 🛠️</li>
          <li>Frustrations with existing products 🤕</li>
        </ul>
        <p className="mt-7">
          I built Probe to help you make products people love! Try it here:
          {/* Probe helps you build the product your customer wants. If you liked
          BuiltBefore, I think you’ll love Probe! */}
        </p>
      </div>
      <div className="flex justify-center mt-10">
        <button
          className={`btn rounded-full bg-indigo-500 text-white hover:bg-indigo-600`}
          onClick={() => console.log('j')}
        >
          <p className={`normal-case text-lg font-normal`}>
            Start Validating Quickly with Probe ⚡
          </p>
        </button>
      </div>
    </div>
  );
};

export default ProbePlug;
