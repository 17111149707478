import React, { useEffect, useState } from 'react';
import logo from '../assets/builtbefore-logo-no-background.png';

const Navbar = () => {
  return (
    <div className="navbar">
      <div
        className="flex-1 font-bold ml-2 cursor-pointer"
        // onClick={navigateHome}
      >
        <div>
          <img src={logo} alt="BuiltBefore Logo" className="w-20 h-20" />
          {/* <span className="text-sky-600 font-light text-sm">BuiltBefore</span> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
