import React, { useState } from 'react';
import Hero from './Hero.tsx';
import SubmitButton from './SubmitButton.tsx';
import SimilarProduct from './SimilarProduct.tsx';
import Navbar from './Navbar.tsx';
import LoadingRingNoCentering from './LoadingRingNoCentering.tsx';
import ProbePlug from './ProbePlug.tsx';
import Footer from './Footer.tsx';
import SearchComponent from './SearchComponent.tsx';
import SearchResults from './SearchResults.tsx';
import { Toaster } from 'react-hot-toast';

const LandingPage = () => {
  const [userQuery, setUserQuery] = useState<string>('');
  const [querySubmitted, setQuerySubmitted] = useState(false);

  console.log(userQuery);

  return (
    <div className="bg-blue-100 text-black min-h-screen relative">
      <Navbar />
      <div className="px-5 sm:w-10/12 xl:w-7/12 sm:m-auto pb-5">
        <Hero
          userQuery={userQuery}
          setUserQuery={setUserQuery}
          setQuerySubmitted={setQuerySubmitted}
        />
        <div className="mt-24 px-5">
          {querySubmitted && <SearchResults query={userQuery} />}
        </div>
        <div className="mt-14">{querySubmitted && <ProbePlug />}</div>
        <Footer />
        <Toaster />
      </div>
    </div>
  );
};

export default LandingPage;
