import React, { useState, useEffect } from 'react';
import smallStar from '../assets/star-img-1.svg';
import largeStar from '../assets/star-img.svg';
import SubmitButton from './SubmitButton.tsx';
import toast from 'react-hot-toast';

interface HeroProps {
  userQuery: string;
  setUserQuery: React.Dispatch<React.SetStateAction<string>>;
  setQuerySubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}

const Hero = ({ userQuery, setUserQuery, setQuerySubmitted }: HeroProps) => {
  const [placeholder, setPlaceholder] = useState<string>(
    'Enter your startup idea in one line to find its closest matches.'
  );
  const [inputValue, setInputValue] = useState<string>('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputValue === '') {
      toast('Please describe your idea.', {
        icon: '✏️',
        style: {
          borderRadius: '50px',
          background: 'rgba(88, 67, 244, 0.35)',
          color: '#fff',
        },
      });
      return;
    }
    setUserQuery(inputValue);
    setQuerySubmitted(true);
  };

  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1025) {
        // 640px can be adjusted based on your design breakpoints
        setPlaceholder('Enter your startup idea in one line...');
      } else {
        setPlaceholder(
          'Enter your startup idea in one line to find its closest matches.'
        );
      }
    };

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <div className="flex flex-col justify-center items-center text-black text-center bg-opacity-60 pt-24 sm:pt-44">
        <h1 className="text-3xl sm:text-4xl font-semibold">
          Find out if your idea has been{' '}
          <span className="bg-blue-800 text-white inline-block px-1 pt-0 pb-1 rounded-md bg-opacity-60">
            built before
          </span>
        </h1>
        <img
          src={smallStar}
          className="absolute inline transform -translate-x-[370px] -translate-y-[50px]"
        />
        <img
          src={largeStar}
          className="absolute inline transform translate-x-[400px] -translate-y-[80px]"
        />
        {/* <img
          src={orangeSphere}
          className="absolute inline transform translate-x-[600px] translate-y-[150px]"
        /> */}
        {/* <div className="absolute inline transform -translate-x-[600px] translate-y-[150px]">
          🪐
        </div> */}
      </div>{' '}
      <div className="font-light mt-12 w-11/12 sm:w-9/12 lg:w-10/12 m-auto">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="w-full bg-transparent text-md text-xl sm:text-1.5xl focus:outline-none text-gray-500"
          placeholder={placeholder}
          onKeyDown={handleEnterKey}
        />
        <hr className="border-gray-400 mt-3" />
      </div>
      <div className="w-full mt-10 flex justify-center">
        <SubmitButton onClick={(event) => handleSubmit(event)} />
      </div>
    </div>
  );
};

export default Hero;
