import React, { useState, useEffect } from 'react';
import Metaphor from 'metaphor-node';
import SimilarProduct from './SimilarProduct.tsx';
import LoadingRingNoCentering from './LoadingRingNoCentering.tsx';
import whiteDownArrow from '../assets/whiteDownArrow.svg';
import smallStar from '../assets/star-img-1.svg';
import largeStar from '../assets/star-img.svg';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig.js';

interface SearchResultsProps {
  query: string;
}

const SearchResults: React.FC<SearchResultsProps> = ({ query }) => {
  const [searchResults, setSearchResults] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const fetchResults = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `http://127.0.0.1:5001/builtbefore/us-central1/getMetaphorSearchResults?query=${encodeURIComponent(
          query
        )}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSearchResults(data.results); // Adjust according to your response structure
      } else {
        console.error('Error fetching data:', response.statusText);
      }
    } catch (err) {
      console.error('Error:', err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (query) {
      fetchResults();
    }
  }, [query]);

  useEffect(() => {
    if (searchResults) {
      setLoading(false);
    }
  }, [searchResults]);

  // useEffect(() => {
  //   setSearchResults(arrayOfObjects);
  //   setLoading(false);
  // }, []);

  if (loading)
    return (
      <div className="flex justify-center">
        <LoadingRingNoCentering />
      </div>
    );

  return (
    <div className="sm:w-11/12 mx-auto">
      <div className="text-center">
        <div className="text-md sm:text-xl text-center font-normal bg-blue-800 text-white inline-block sm:px-1 pt-0 pb-1 rounded-md bg-opacity-40">
          <img className="inline-block ml-2 w-5" src={largeStar} /> Closest
          products to your idea{' '}
          <img className="inline-block mr-2 w-7" src={smallStar} />
        </div>
      </div>
      {searchResults.map((result) => (
        <SimilarProduct name={result.title} url={result.url} />
      ))}
    </div>
  );
};

export default SearchResults;
