import React, { useEffect, useState } from 'react';
import defaultLogo from '../assets/default-logo.png';

interface SimilarProductProps {
  name: string;
  url: string;
}

const SimilarProduct = ({ name, url }: SimilarProductProps) => {
  const [description, setDescription] = useState<any>('');
  const [logo, setLogo] = useState<any>(defaultLogo);
  const [loading, setLoading] = useState(true);

  const fetchUrlMetadata = async (targetUrl) => {
    try {
      const proxyUrl = 'https://corsproxy.io/?' + encodeURIComponent(targetUrl);
      const response = await fetch(proxyUrl);
      const html = await response.text();
      const parser = new DOMParser();
      const htmlDocument = parser.parseFromString(html, 'text/html');
      const metatags = htmlDocument.getElementsByTagName('meta');
      const links = htmlDocument.getElementsByTagName('link');

      let description = null;
      let logo = null;

      // Helper function to check if URL is absolute
      const isAbsoluteUrl = (url) => {
        const regex = new RegExp('^(?:[a-z]+:)?//', 'i');
        return regex.test(url);
      };

      // First, try to find a logo in the meta tags (less common)
      for (let tag of metatags) {
        const property = tag.getAttribute('property');
        const content = tag.getAttribute('content');

        if (
          !description &&
          (property === 'og:description' || property === 'description')
        ) {
          description = content;
        }

        if (
          (property === 'og:logo' || property === 'logo') &&
          isAbsoluteUrl(content)
        ) {
          logo = content;
          break; // If a logo is found in meta, use it
        }
      }

      // If no logo is found in meta tags, look in the link tags (more common)
      if (!logo) {
        for (let link of links) {
          const rel = link.getAttribute('rel');
          const href = link.getAttribute('href');

          if (
            (rel === 'icon' || rel === 'shortcut icon') &&
            isAbsoluteUrl(href)
          ) {
            logo = href;
            break;
          }
        }
      }

      console.log('Logo: ', logo);
      return { description, logo };
    } catch (error) {
      console.error('Error fetching metadata:', error);
      return null;
    }
  };

  const handleImageError = (e) => {
    e.target.onerror = null; // Prevent infinite loop in case defaultLogo is also broken
    e.target.src = defaultLogo;
  };

  useEffect(() => {
    const setUrlMetadata = async () => {
      const result = await fetchUrlMetadata(url);
      if (result) {
        const { description, logo } = result;
        setDescription(description);
        if (logo) {
          setLogo(logo);
        }
      } else {
        // Handle the case where result is null
        console.error('No metadata found for URL:', url);
      }
      setLoading(false);
    };

    setUrlMetadata();
  }, [url]);

  if (loading) return;

  return (
    <div className="flex mt-10">
      <img
        className="w-12 h-12 rounded-xl mr-5"
        src={logo}
        onError={handleImageError}
      />
      <div className="w-10/12">
        <h1 className="text-2xl font-medium">{name}</h1>
        <a className="text-indigo-400 font-regular text-lg" href={url}>
          {url}
        </a>
        <p className="text-lg">{description}</p>
      </div>
    </div>
  );
};

export default SimilarProduct;
