import React from 'react';
import enterIcon from '../assets/enterIcon.svg';

interface SubmitButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const SubmitButton = ({ onClick }: SubmitButtonProps) => {
  return (
    <button
      className={`btn rounded-full bg-transparent sm:mr-4 text-grey-400 hover:text-white border border-gray-400 sm:border-grey-800 hover:bg-blue-800 hover:bg-opacity-60 w-auto sm:w-auto text-lg sm:text-xl font-extralight normal-case text-black px-5`}
      onClick={onClick}
    >
      <p>Enter</p>
      <img src={enterIcon} alt="enter icon" className="w-4 h-4" />
      <p>to Search</p>
    </button>
  );
};

export default SubmitButton;
