import React from 'react';

const Footer = () => {
  return (
    <div className="text-center mt-20 absolute bottom-5 left-0 right-0">
      {' '}
      Made with 💜 by{' '}
      <a
        href="https://linkedin.com/in/savannahfeder"
        className="text-indigo-600"
      >
        Savannah
      </a>
    </div>
  );
};

export default Footer;
